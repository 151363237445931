import React from "react";
import Layout from "../templates_/Layout";

function About() {
  return (
    <>
      <section class="container my-5">
        <div className="container-fluid align-middle head-img mb-3">
          <img src="../images/t6.avif" className="img-fluid" alt="Image" />
          <p className="centered">About Swazitrac</p>
        </div>

        <section id="overview" class="mb-5">
          <h2>Company Overview</h2>
          <p>
            Established in 1975, Swazi-Trac has grown to become Eswatini's sole
            agent for the world's finest agricultural and construction machinery
            and equipment. Whether your business is farming, earthmoving, or
            beautifying home gardens, we are here to assist with top-quality
            products and services.
          </p>
          <p>
            Our loyal clientele continues to grow, driven by our exceptional
            products and superior after-sales support.
          </p>
          <p>
            We focus on providing top-quality machinery backed by excellent
            technical and parts support. We stock a wide selection of genuine
            parts, ensuring quick deliveries from South Africa, the US, and
            European parts distribution centers.
          </p>
          <p>
            Our factory-trained technicians are equipped with specialized tools
            and the latest diagnostic equipment. We also offer on-site services
            throughout eSwatini with our mobile service vehicles.
          </p>
          <p>
            This strategy has led to a high level of customer satisfaction,
            dominant market shares, and strong customer goodwill across the
            region.
          </p>
        </section>

        <section id="values" class="mb-5">
          <h2>Our Values</h2>
          <ul class="list-group">
            <li class="list-group-item">
              We are driven by competition but never compromise our honesty and
              integrity.
            </li>
            <li class="list-group-item">
              We earn respect through hard work and commitment.
            </li>
            <li class="list-group-item">
              We make intelligent commitments and deliver on them with urgency.
            </li>
            <li class="list-group-item">
              We deliver value to our customers and reject substandard work.
            </li>
            <li class="list-group-item">
              We treat all customers with respect, courtesy, and fairness.
            </li>
            <li class="list-group-item">
              We work hard and create a constructive, team-oriented environment.
            </li>
            <li class="list-group-item">
              We hold employees accountable and provide the necessary training
              and support.
            </li>
            <li class="list-group-item">
              We recognize and reward positive performance and loyalty.
            </li>
            <li class="list-group-item">
              We encourage constructive criticism with potential solutions.
            </li>
          </ul>
        </section>

        <div className="container-fluid align-middle head-img">
          <img src="images/t8.jpg" className="img-fluid" alt="Image" />
          <p className="centered">Community Involvement</p>
        </div>

        <section id="community" class="bg-light p-4 mb-5">
          <p>
            Swazi-Trac has a long history of supporting growth and development
            in eSwatini. We proudly support the Hhohho Super League, which
            consists of 24 teams and over 500 active players, promoting sport,
            fair play, and youth development.
          </p>
          <p>
            We also sponsor the 3 Reserves MTB race, the biggest cycling event
            in eSwatini, recognized by the Southern African cycling federation.
          </p>
          <p>
            Swazi-Trac values family ethics, recognizing that our employees are
            key to our continued success. We contribute to various educational
            fundraising events annually, supporting the growth and development
            of our community.
          </p>
          <p>
            We are honored to contribute to the sportsmen, women, and youth in
            eSwatini, and we thank our partners who help us achieve our
            community goals.
          </p>
        </section>

        <section id="mission" class="py-5">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h2 class="mb-4">Mission Statement</h2>
                <p>
                  Swazi-Trac’s mission is to be a profitable and successful
                  Agri-Business.
                </p>
                <p>
                  We will accomplish our mission by providing superior customer
                  service in a timely, professional, and friendly manner. We
                  will consistently strive to improve the way we do business
                  through employee training, systems development, and
                  technological innovation.
                </p>
                <p>
                  Our goal is to be the best-managed Agri-Business in eSwatini
                  and to be a "World Class" John Deere Dealer. By accomplishing
                  our goal, we will support the economic activities that help
                  make eSwatini an attractive area for our families and the
                  broader community we work with, to live and grow.
                </p>
                <blockquote class="blockquote">
                  <p class="mb-0">
                    "I will never put my name on a product that does not have in
                    it, the best that is in me."
                    <br />
                    <small>— John Deere</small>
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        <section id="sales-team" class="bg-light py-5">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h2 class="mb-4">Our Sales Team</h2>
                <p>
                  A capable sales team comprising Mr. Elisha Mtetwa, Mr. Moses
                  Motsa, is able to offer combined agricultural solutions
                  experience of more than 25 years to the agricultural sector,
                  while Mr. Thulani Mathonsi is able to offer materials handling
                  (Forklifts) and construction equipment solutions to the
                  commercial, warehousing, and retail sectors. In addition, Mr.
                  Ratta Mazibuko contributes more than 30 years of machinery
                  operation and training and is our operational training
                  provider.
                </p>
                <p>
                  As with all Swazi-Trac staff, sales personnel are registered
                  with John Deere’s Online University program and attend annual
                  training both online and at John Deere’s training facility in
                  South Africa, guaranteeing that they are current with John
                  Deere’s developments, improvements, and product lines. This
                  means that the Swazi-Trac sales team is able to offer the
                  customer the best service, information, products, support, and
                  solutions, thus ensuring that the customer gets the right
                  equipment for the task at hand.
                </p>
                <p>
                  Swazi-Trac is eSwatini’s agricultural provider of choice as
                  attested to by a market share of more than 80%, maintained
                  over the last two growing seasons. In unit terms, Swazi-Trac
                  has supplied more than 100 John Deere tractors into the Swazi
                  market over the past two growing seasons. A strong indication
                  of our customers' confidence in our product offering, support,
                  and service. Swazi-Trac has supplied more than 525 John Deere
                  units into the Swazi market over the past 10 growing seasons.
                  These units, as well as any of the units we have supplied over
                  40 years of continuous operation, have our full backup and
                  support.
                </p>
                <p>
                  As a result, we are proud to report that there are units in
                  current daily operation that have original engines and
                  transmissions with more than 30,000 hours on their clocks,
                  testament to the successful partnership between Swazi-Trac’s
                  dedication to their customers in eSwatini and John Deere’s
                  dependable reliability.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="service-team" class="bg-light py-5">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h2 class="mb-4">Our Service Team</h2>
                <p>
                  Under the capable guidance of our Service Manager - Lionel
                  Wasserman, Senior Charge hand - Sydney Mthunzi, and Workshop
                  Admin Clerk - Homeboy Fakudze, Swazi-Trac currently employs
                  nine technicians, fourteen apprentices, two small engine
                  mechanics, two welding/fabricators, two painters, and
                  depending on availability, a student attachment or two.
                </p>
                <p>
                  Our technicians and mechanics are well trained and equipped,
                  and operate from a workshop based in Matsapha with 10 covered
                  workbays. Mobile service vehicles provide on-site service
                  throughout eSwatini.
                </p>
                <p>
                  Further, a team comprised of a senior technician and three
                  apprentices are permanently based in Big Bend as well as a
                  consignment stock of critical parts based at Umoba in Big Bend
                  in order to service the Lubombo region more effectively.
                  Siphofaneni region will be supported from our Matata base and
                  consignment stock may be situated at specified locations as
                  required by our customers depending on demand.
                </p>
                <p>
                  Since early 2020, we have established an additional workshop
                  and a team comprised of a technician and three apprentices
                  based at Tambankulu, in order to more effectively and
                  efficiently service our valued customers located around Mhlume
                  and Simunye. Our intention is to expand our offering from the
                  Tambankulu location by stocking parts and consumables for our
                  customers' convenience.
                </p>
                <p>
                  Swazi-Trac service and support staff are registered with John
                  Deere’s Online University program and attend annual training
                  both online and at John Deere’s training facility,
                  guaranteeing that they are current with John Deere’s
                  developments, improvements, and product lines. In addition,
                  selected technicians and mechanics have received additional
                  training of a similar type with both JCB and Doosan.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
function AboutPage() {
  return (
    <Layout
      children={
        <>
          {/* <HomePage /> */}
          <About />
        </>
      }
    />
  );
}
export default AboutPage;
