import { experimentalSetDeliveryMetricsExportedToBigQueryEnabled } from "firebase/messaging/sw";
import React from "react";
import { Helmet } from "react-helmet";
import ProductGrid from "./productGrid";
import ProductList from "../components/ProductList";
import ProductShowcase from "../components/ProductShowcase";


function Falcon() {
  return (
    <>
      <Helmet>
        <title>SwaziTrac | Falcon Equipment</title>
        <meta
          name="description"
          content="Explore SwaziTrac, Eswatinis leading Dealer of rotary cutters and agricultural equipment. Discover Falcon's range of implements including mowers, mulchers, haymakers, and spreaders."
        />
        <meta
          name="keywords"
          content="Falcon Equipment, Tractors, SwaziTrac, agricultural machinery, Swaziland, Eswatini, South Africa, rotary cutters, mowers, mulchers, haymakers, spreaders, farming equipment"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="SwaziTrac | Falcon Equipment" />
        <meta
          property="og:description"
          content="Falcon offers a range of high-quality agricultural implements designed to boost productivity in farming, turf, and industrial applications."
        />
        <meta property="og:image" content="/images/falconhead2.jpg" />
        <meta property="og:url" content="https://swazitrac.com/falcon" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Falcon Agricultural Equipment | Innovative Solutions for South Africa"
        />
        <meta
          name="twitter:description"
          content="Explore Falcon's range of equipment, including flail mowers, haymakers, and spreaders, ideal for agricultural needs in South Africa."
        />
        <meta name="twitter:image" content="/images/falconhead2.jpg" />
      </Helmet>
      <main class="container my-5">
        <section class="mb-5 ">
          <div class="head-img container-fluid ">
            <img
              src="../images/flail_header.jpg"
              class="img-fluid border-top-falcon"
              alt="Image"
            />
            <p>
              Falcon: The Largest Manufacturer of Rotary Cutters in Africa.
            </p>
          </div>

          {/* <!-- Product links --> */}
          <ProductList data={falconProducts} />
          <hr />
          <div class="container-fluid text-center">
            <h1>Falcon Products</h1>
            
            <ProductGrid products={falconProducts} />
            <hr />
            <h3>Falcon FLail Implements</h3>
            <br />
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../images/falconflailmower.jpg"
                  class="img-fluid"
                  alt="Forwarder Image"
                />
                <p>Falcon | Flail Mowers</p>
              </div>
              <div class="col-sm-3">
                <img
                  src="../images/forestry/fellerbuncher_1.avif"
                  class="img-fluid"
                  alt="Feller Buncher Image"
                />
                <p>Falcon | Flail Cutters</p>
              </div>
              <div class="col-sm-3">
                <div class="well">
                  <p>
                    The Falcon Flail Mower is available in a range of sizes with
                    working widths from 2,1 to 2,9 m and suitable for tractors
                    between 40 and 60 kW. It is intended for a specific range of
                    cutting applications. These include mulching of maize/wheat
                    residue, pasture maintenance, orchard maintenance, general
                    bush clearing, and maintenance of roadways and other public
                    amenities. Unlike Falcon’s Flail Cutters, it is not intended
                    for sustained shredding and mulching applications, but
                    rather suitable for maintenance applications in areas free
                    of excessively large solid materials (typically less than 25
                    mm in diameter). Due to its horizontal layout and enclosed
                    cutting chamber, this implement provides predictable and
                    consistent results with high levels of safety.
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div class="">
            <br />
            <p>
              Falcon Equipment is a trusted leader in the South African
              agricultural sector, known for its high-quality implements
              designed to boost productivity in farming, turf, and industrial
              applications. With a legacy rooted in innovation, Falcon offers a
              wide range of equipment, including Flail Implements, Haymakers®,
              Mowers, Mulchers, and Spreaders, catering to diverse needs like
              slashing, haymaking, and material handling. In addition to its
              signature products, Falcon partners with international brands such
              as Amazone and Votex®, bringing globally recognized equipment like
              sprayers, specialized mowers, and tillage machinery to local
              markets.
            </p>
            <br></br>
            <p>
              Discover the full range of Falcon’s solutions and how they can
              transform your agricultural operations
            </p>
            <br />
          </div>

          <div class="container-fluid">
            <img src="../images/flail_header.jpg" class="img-fluid" alt="F" />
          </div>

          <br />

          
        </section>
      </main>
    </>
  );
}
export default Falcon;

const falconProducts = [
  {
    id: 1,
    cat: "Flail Implement",
    name: "Falcon Flail Mowers",
    image: "../images/falconflailmower.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/flail-implements/falcon-flail-mowers",
  },
  {
    id: 2,
    cat: "Flail Implement",
    name: "Falcon Flail Cutters",
    image: "/images/falconflailcutter.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/flail-implements/falcon-flail-cutters",
  },
  {
    id: 3,
    cat: "HayMaker",
    name: "Falcon Haymakers - Small",
    image: "/images/falconhaymakersmall.jpg",
    link: "/products/3",
  },
  {
    id: 4,
    cat: "HayMaker",
    name: "Falcon Haymakers - intermediate",
    image: "/images/falconhaymakerintermediate.jpg",
    link: "/products/4",
  },
  {
    id: 5,
    cat: "HayMaker",
    name: "Falcon Haymakers - Large",
    image: "/images/falconhaymakerlarge.jpg",
    link: "/products/5",
  },
  {
    id: 6,
    cat: "Mower",
    name: "Falcon Blower Mowers",
    image: "/images/falconblowermower.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/mowing/falcon-blowermowers",
  },
  {
    id: 7,
    cat: "Mulcher",
    name: "Falcon GrassMulchers",
    image: "/images/falcongrassmulcher.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/mowing/falcon-graasmulchers",
  },
  {
    id: 8,
    cat: "Mower",
    name: "Falcon Roller Mowers",
    image: "/images/falconrollermowers.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/mowing/falcon-rollermowers",
  },
  {
    id: 9,
    cat: "Mower",
    name: "Falcon TURFPRO Finishing Mowers",
    image: "/images/falconturfpromower.png",
    link: "https://www.falconequipment.co.za/products/falcon/mowing/falcon-specialised-mowers---turfpro-finishing-mower",
  },
  {
    id: 10,
    cat: "Mower",
    name: "Falcon RotaGang - Specilized Mowers",
    image: "/images/falconrotagang.png",
    link: "https://www.falconequipment.co.za/products/falcon/mowing/falcon-specialised-mowers---rotogang",
  },
  {
    id: 11,
    cat: "Mulcher",
    name: "Falcon Side Discharge Mulchers",
    image: "/images/falconsidemulcher.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/mulching/falcon-side-discharge-mulchers",
  },
  {
    id: 12,
    cat: "Mulcher",
    name: "Falcon Field Mulchers",
    image: "/images/falconfieldmulcher.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/mulching/falcon-fieldmulchers",
  },
  {
    id: 13,
    cat: "Slasher",
    name: "Falcon Slasher - Light Duty",
    image: "/images/falconslasherlight.png",
    link: "hosting:sites:list",
  },
  {
    id: 14,
    cat: "Slasher",
    name: "Falcon Slasher - Medium Duty",
    image: "/images/falconslashermedium.png",
    link: "https://www.falconequipment.co.za/products/falcon/slashing/falcon-slashers---medium-duty",
  },
  {
    id: 15,
    cat: "Slasher",
    name: "Falcon Slasher - Heavy Duty",
    image: "/images/falconslasherheavy.png",
    link: "https://www.falconequipment.co.za/products/falcon/slashing/falcon-slashers---heavy-duty",
  },
  {
    id: 16,
    cat: "Slasher",
    name: "Falcon Slasher - Extra Heavy Duty",
    image: "/images/falconslasherextraheavy.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/slashing/falcon-slashers---extra-heavy-duty",
  },
  {
    id: 17,
    cat: "Slasher",
    name: "Falcon FlexWing Slasher",
    image: "/images/falconflexwingslasher.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/slashing/falcon-slashers---flexwing-slasher",
  },
  {
    id: 18,
    cat: "Slasher",
    name: "Falcon Trailed Slasher",
    image: "/images/falcontrailedslasher.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/slashing/falcon-trailed-slasher",
  },
  {
    id: 19,
    cat: "Tillage",
    name: "Falcon Bedformer",
    image: "/images/falconbedformer.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/tillage/falcon-bedformers",
  },
  {
    id: 20,
    cat: "Tillage",
    name: "Falcon Rotavator",
    image: "/images/falconrotavator.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/tillage/falcon-rotavators",
  },
  {
    id: 21,
    name: "Falcon RotorSpreader",
    cat: "Spreader",
    image: "/images/falconrotorspreader.png",
    link: "https://www.falconequipment.co.za/products/falcon/spreaders/falcon-rotorspreader",
  },
  {
    id: 22,
    cat: "Bale Grabber",
    name: "Falcon Bale Grab",
    image: "/images/falconbale.jpg",
    link: "https://www.falconequipment.co.za/products/falcon/bale-grab",
  },
  {
    id: 23,
    cat: "Spreader",
    name: "Falcon LimeSpreader",
    image: "/images/falconlimespreader.png",
    link: "https://www.falconequipment.co.za/products/falcon/spreaders/falcon-limespreader",
  },
  // EOB
];