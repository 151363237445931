import React from "react";
import ContactPage from "../components/Contact";

function support() {
  return (
    <>
      <section class="container my-5">
        <div class="text-center">
          <img
            src="../images/r2.jpg"
            class="img-fluid"
            alt="Agricultural Machinery"
          />
          <h1 class="my-3">SwaziTrac Support</h1>
          <p>
            lorem ipsum dolor sit am rem, consectetur adipiscing elit in diam nonumy
          </p>
        </div>

        <main class="container-fluid flex-column my-5">
          <section id="parts-and-services" class="mb-5">
            <h2>Parts and Services</h2>
            <p>
              At SwaziTrac, we offer a wide range of parts and services to keep
              your equipment running smoothly. Our experienced team is dedicated
              to providing top-quality products and services to meet your needs.
            </p>
            <div class="row">
              <div class="col-lg-4">
                <h3>Parts</h3>
                <p>
                  We carry a large inventory of genuine OEM parts for all major
                  brands of equipment. Our knowledgeable staff can help you find
                  the right part for your needs, and we offer fast, reliable
                  shipping to get your parts to you quickly.
                </p>
                <a href="#" class="btn btn-primary">
                  Shop Parts
                </a>
              </div>
              <div class="col-lg-4">
                <h3>Services</h3>
                <p>
                  Our skilled technicians can provide a wide range of services,
                  including routine maintenance, repairs, and upgrades. We use
                  the latest diagnostic tools and techniques to ensure that your
                  equipment is running at peak performance.
                </p>
                <a href="#" class="btn btn-primary">
                  Schedule Service
                </a>
              </div>
              <div class="col-lg-4">
                <h3>Support</h3>
                <p>
                  We understand that equipment downtime can be costly, so we
                  offer 24/7 support to help you get back up and running as
                  quickly as possible. Our team is always available to answer
                  your questions and provide expert advice.
                </p>
                <a href="#" class="btn btn-primary">
                  Contact Support
                </a>
              </div>
            </div>
          </section>
        </main>
      </section>
      <ContactPage />
    </>
  );
}
export default support;
