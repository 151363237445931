import React from "react";
import { Link } from "react-router-dom";

function ProductGrid({ products }) {
  return (
    <div className="container mt-4">
      <div className="row">
        {products.map((product) => (
          <div className="col-md-4 mb-4" key={product.id}>
            <div className="card h-100">
              <Link to={product.link}>
                <img
                  src={product.image}
                  className="card-img-top"
                  alt={product.name}
                />
              </Link>
              <div className="card-body">
                <h5 className="card-title">{product.name}</h5>
                <Link to={product.link} className="btn btn-primary">
                  View Product
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductGrid;