import React from "react";
import PropTypes from "prop-types";

const MarketingCard = ({ image, text }) => {
  return (
    <div className="container my-4">
      <div className="row align-items-center">
        {/* Image Section */}
        <div className="col-md-6 text-center">
          <img 
            src={image} 
            alt="Marketing" 
            className="img-fluid rounded" 
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
        {/* Text Section */}
        <div className="col-md-6">
          <p 
            className="lead" 
            style={{ fontSize: "1.25rem", lineHeight: "1.8" }}
          >
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};

MarketingCard.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default MarketingCard;
