import React from "react";
import Layout from "../templates_/Layout";
import Email from "./EmailForm";
import EmailForm from "./EmailForm";

function Contact() {
  return (
    <div>
      <section id="contact" className="container-fluid mb-5">
        <div className="full-width-green top-rounded text-center py-3">
          Contact Us
        </div>
        <ul className="bg-dark nav nav-tabs" id="contactTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="form1-tab"
              data-bs-toggle="tab"
              href="#form1"
              role="tab"
              aria-controls="form1"
              aria-selected="true"
            >
              Sales
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="form2-tab"
              data-bs-toggle="tab"
              href="#form2"
              role="tab"
              aria-controls="form2"
              aria-selected="false"
            >
              Support
            </a>
          </li>
        </ul>

        <div className="tab-content mt-4" id="contactTabsContent">
          {/* Sales Tab */}
          <div
            className="tab-pane fade show active"
            id="form1"
            role="tabpanel"
            aria-labelledby="form1-tab"
          >
            <div className="row">
              <div className="col-md-6">
                <EmailForm />
              </div>

              <div className="col-md-6">
                <h2>Contact Information</h2>
                <address>
                  <strong>Swazi-Trac (Pty) Ltd</strong>
                  <br />
                  P. O. Box 170, Matsapha, M202, eSwatini
                  <br />
                  Tel / Fax: +268 2518-4555/6
                  <br />
                  Email:{" "}
                  <a href="mailto:reception@swazitrac.co.sz">
                    reception@swazitrac.co.sz
                  </a>
                  <br />
                  Website:{" "}
                  <a
                    href="http://www.swazitrac.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.swazitrac.com
                  </a>
                  <h4>
                    <strong>Office Hours</strong>
                    <p>Sunday Closed</p>
                    <p>Monday 7am - 4:30pm</p>
                    <p>Tuesday 7am - 4:30pm</p>
                    <p>Wednesday 7am - 4:30pm</p>
                    <p>Thursday 7am - 4:30pm</p>
                    <p>Friday 7am - 4:30pm</p>
                    <p>Saturday Closed</p>
                  </h4>
                </address>
              </div>
            </div>
          </div>

          {/* Support Tab */}
          <div
            className="tab-pane fade"
            id="form2"
            role="tabpanel"
            aria-labelledby="form2-tab"
          >
            <div className="row">
              <div className="col-md-6">
              <EmailForm token={process.env.EMAILKEY}/>
              </div>
              <div className="col-md-6">
                <h2>Contact Information</h2>
                <address>
                  <strong>Swazi-Trac (Pty) Ltd</strong>
                  <br />
                  P. O. Box 170, Matsapha, M202, eSwatini
                  <br />
                  Tel / Fax: +268 2518-4555/6
                  <br />
                  Email:{" "}
                  <a href="mailto:reception@swazitrac.co.sz">
                    reception@swazitrac.co.sz
                  </a>
                  <br />
                  Website:{" "}
                  <a
                    href="http://www.swazitrac.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.swazitrac.com
                  </a>
                  <br />
                  <h4>
                    <strong>Office Hours</strong>
                    <p>Sunday Closed</p>
                    <p>Monday 7am - 4:30pm</p>
                    <p>Tuesday 7am - 4:30pm</p>
                    <p>Wednesday 7am - 4:30pm</p>
                    <p>Thursday 7am - 4:30pm</p>
                    <p>Friday 7am - 4:30pm</p>
                    <p>Saturday Closed</p>
                  </h4>
                </address>
              </div>
            </div>
          </div>
        </div>
        <div className="map-container mt-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3570.5546980694744!2d31.316332276863733!3d-26.50227897689404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1eef4972626a1ca1%3A0x389adf13c814995c!2sSwaziTrac%20Pty%20Ltd.%20John%20Deere%20Dealer%2C%20Eswatini!5e0!3m2!1sen!2sza!4v1723395208858!5m2!1sen!2sza"
            width="800"
            height="600"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </div>
  );
}
function ContactPage() {
  return (
    <Layout
      children={
        <>
          {/* <HomePage /> */}
          <Contact />
        </>
      }
    />
  );
}

export default ContactPage;
