import React from 'react';

const ProductList = ({ data }) => {
  // Group data by category
  const groupedData = data.reduce((acc, item) => {
    acc[item.cat] = acc[item.cat] || [];
    acc[item.cat].push(item);
    return acc;
  }, {});

  return (
    <section className="container-fluid">
      {Object.keys(groupedData)
        .sort() // Sort categories alphabetically
        .reduce((rows, category, index) => {
          // Determine which row the category should belong to
          const rowIndex = Math.floor(index / 4); // Change '4' to set the number of columns per row
          if (!rows[rowIndex]) rows[rowIndex] = [];
          rows[rowIndex].push(category);
          return rows;
        }, [])
        .map((row, rowIndex) => (
          <div className="row mb-3" key={rowIndex}>
            {row.map((category) => (
              <div className="col-6 col-sm-3" key={category}>
                {/* Adjust 'col-sm-3' for the number of columns per row */}
                <h4 className="border border-2 rounded-1 p-1">{category}</h4>
                <ul>
                  {groupedData[category]
                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort items alphabetically
                    .map((item) => (
                      <li key={item.id}>
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                          {item.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        ))}
    </section>
  );
  
};

export default ProductList;
