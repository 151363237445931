import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppRoutes from './App';
import { BrowserRouter } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    {<app />}
    <AppRoutes/>
  </BrowserRouter>
);


// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB271T4e4_o4NdZX_7fQFc_N5uXzDNDu2E",
  authDomain: "quicker-swazi.firebaseapp.com",
  projectId: "quicker-swazi",
  storageBucket: "quicker-swazi.firebasestorage.app",
  messagingSenderId: "1096807754380",
  appId: "1:1096807754380:web:b517054fd7baa4e1cb29a9",
  measurementId: "G-DQVM79XLBN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
