import React from "react";

function Construction() {

  return (
    <>
    <main className = "conatiner my-5">
    <div className="container-fluid head-img">
      <img src="../images/construction-web-banner-slide4.avif" className="img-fluid" alt="Image"/>
      <p>Unleash the power and precision of John Deere construction equipment on your next project. Built for the most
        demanding environments, John Deere offers a wide range of machinery, including backhoe loaders, excavators, and
        motor graders, all designed to maximize productivity and efficiency.
      </p>
    </div>


    <div className="container-fluid text-center my-5">
      <h3>What We Sell</h3><br/>
      <div className="row">
        <div className="col-sm-4">
          <img src="../images/460_p_tier_adt_r4x001779.webp" className="img-fluid" alt="Image/"/>
          <p>Dump Trucks</p>
        </div>
        <div className="col-sm-4">
          <img src="../images/750j_ii_dozer_.webp" className="img-fluid" alt="Image"/>
          <p>Dozers</p>
        </div>
        <div className="col-sm-4">
          <img src="../images/300glc_excavator_r4x000888.avif" className="img-fluid" alt="Image"/>
          <p>Excavators</p>
        </div>
        <div className="col-sm-4">
          <div className="well">
            <p>Main text here about construction equipment</p>
          </div>
          <div className="well">
            <p>Some text..</p>
          </div>
        </div>
      </div>
    </div>

    {/* <!-- Product links --> */}
    <section className="container-fluid">
      <div className="row">
        <div className="col-sm">
          <h4 className="border border-2 rounded-1 p-1">Dozers</h4>
          <ul>
            <li><a href="https://www.deere.africa/en/dozers/450-p-dozer/">450 P-Tier</a></li>
            <li><a href="https://www.deere.africa/en/dozers/550j-crawler-dozer/">550J</a>
            </li>
            <li><a href="https://www.deere.africa/en/dozers/650-p-dozer/">650 P-Tier</a></li>
            <li><a href="https://www.deere.africa/en/dozers/700j-ii-crawler-dozer/">700J-II</a></li>
            <li><a href="https://www.deere.africa/en/dozers/750j-ii-crawler-dozer/">750J-II</a></li>
            <li><a href="https://www.deere.africa/en/dozers/850j-ii-crawler-dozer/">850J-II</a></li>
            <li><a href="https://www.deere.africa/en/dozers/950p-tier/">950 P-Tier</a></li>
            <li><a href="https://www.deere.africa/en/dozers/1050p-tier/">1050 P-Tier</a></li>
          </ul>
        </div>
        <div className="col-sm">
          <h4 className="border border-2 rounded-1 p-1">Skid Steers</h4>
          <ul>
            <li><a href="https://www.deere.africa/en/skid-steers/316gr-skid-steer/">316GR iT4</a>
            </li>
            <li><a href="https://www.deere.africa/en/skid-steers/318g-skid-steer/">318G iT4</a></li>
          </ul>
        </div>
        <div className="col-sm">
          <h4 className="border border-2 rounded-1 p-1">Excavators</h4>
          <ul>
            <li><a href="https://www.deere.africa/en/excavators/e210-ii-excavator/">E210-II</a></li>
            <li><a href="https://www.deere.africa/en/excavators/e260-ii-excavator/">E260-II</a></li>
            <li><a href="https://www.deere.africa/en/excavators/e300-ii-excavator/">E300-II</a></li>
            <li><a href="https://www.deere.africa/en/excavators/e380-ii-excavator/">E380-II</a></li>
          </ul>
        </div>
        <div className="col-sm">
          <h4 className="border border-2 rounded-1 p-1">Articulated Dump Trucks</h4>
          <ul>
            <li><a href="https://www.deere.africa/en/articulated-dump-trucks/260-p-adt/">260 P-Tier</a></li>
            <li><a href="https://www.deere.africa/en/articulated-dump-trucks/310-p-adt/">310 P-Tier</a></li>
            <li><a href="https://www.deere.africa/en/articulated-dump-trucks/410-p-adt/">410 P-Tier</a></li>
            <li><a href="https://www.deere.africa/en/articulated-dump-trucks/460-p-adt/">460 P-Tier</a></li>
          </ul>
        </div>
      </div>
    </section>

    <div className="container-fluid my-5">
      <div className="row">
        <h2 className="text-center full-width-grey top-rounded mx-5">Operation Centre - Keep Your Fleet Rolling</h2>
        <div className="d-flex align-items-center">
          <div className="col-sm-6 col-xs-12 text-left">
            <img src="../images/c1.jpg" className="img-responsive img-fluid w-50 h-50" alt="Image"/>
          </div>

          <div className="col-sm-6 col-xs-12 text-start">
            <p className="d-inline-block post">"Being successful means being efficient.

              Machine Monitoring uses the latest connectivity and alert-management tools to filter and analyze machine
              data, allowing you to identify critical issues and take action — sometimes before you even know there is a
              problem.

              <br />
              #JohnDeereAfricaMiddleEast "
            </p>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div className="container-fluid my-5">
      <div className="row">
        <h2 className="text-center full-width-grey top-rounded mx-5">Machine Monitoring</h2>
        <div className="d-flex align-items-center">
          <div className="col-sm-6 col-xs-12 text-left">
            <img src="../images/c4.jpg" className="img-responsive img-fluid w-50 h-50" alt="Image"/>
          </div>

          <div className="col-sm-6 col-xs-12 text-start">
            <p className="d-inline-block post">" Keep your fleet rolling.
              Your operation can’t afford to come to a grinding halt, so let us monitor the health and performance of
              your
              John Deere equipment in real time from our Operations Center. We’ll alert you before pebble-sized problems
              become boulders in your path.
              Reach out to our experts and rock your jobsite
              <br />
              #JohnDeereAfricaMiddleEast "
            </p>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div className="container-fluid my-5">
      <div className="row">
        <h2 className="text-center full-width-grey top-rounded mx-5">Expert Alert</h2>
        <div className="d-flex align-items-center">
          <div className="col-sm-6 col-xs-12 text-left">
            <img src="../images/c2.jpg" className="img-responsive img-fluid w-50 h-50" alt="Image"/>
          </div>

          <div className="col-sm-6 col-xs-12 text-start">
            <p className="d-inline-block post">"Remain proactive with Expert Alerts
              Expert Alert's monitoring system that allow your dealer to
              keep eyes on your fleet – so you can focus on the job.
              When you choose to enable Expert Alerts, your dealer can proactively analyze telematics data to uncover
              potential component failures before they disrupt your operations.
              <br />
              #JohnDeereAfricaMiddleEast "
            </p>
          </div>
        </div>
      </div>
    </div>

    <br />

    

    <div className="container-fluid">
      <div className="image-container">
        <img src="../images/310p_adt_r4j018616_large.avif" className="img-fluid" alt="Image"/>
        <img src="../images/full-Construction-1.webp" className="img-fluid" alt="Image"/>
      </div>
      <p className="">John Deere machines are engineered with advanced technology, such as Intelligent Boom Control for
        smoother
        operations and automatic grade control that ensures perfect results every time. Durable and easy to maintain,
        these machines reduce operational downtime, allowing you to focus on getting the job done. With enhanced
        operator comfort and intuitive controls, John Deere equipment ensures that your team works more efficiently,
        reducing fatigue and increasing overall output.

        Invest in John Deere construction equipment and experience unparalleled performance, durability, and
        support—engineered to get the job done right.</p>
    </div>
    </main>
    </>
);}

export default Construction;