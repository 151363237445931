import React from "react";
import { Helmet } from "react-helmet";
import ProductGrid from "./productGrid";
import ProductList from "../components/ProductList";
import ProductShowcase from "../components/ProductShowcase";


function Turf(props) {
  return (
    <>
    <main class="container my-5">
      <section className="shadow">
        <div className="container-fluid align-middle head-img mb-3">
          <img src="../images/t6.avif" className="img-fluid" alt="Image" />
          <p className="centered">
            High Quality Turf Is Good
          </p>
        </div>
        
        <ProductShowcase products={turfproducts} />

        <ProductGrid products={turfproducts} />

        <ProductList data={turfproducts} />        

        <div className="container-fluid">
          <img
            src="../images/r4a063080-ag-1366x347.jpg"
            className="img-fluid"
            alt="Image"
          />
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
            quod, itaque repellat dolorum ab dolore neque aut vitae optio culpa
            labore pariatur quos, iste fuga ad. Totam facilis quidem voluptatem.
          </p>
        </div>

        <br />

        
      </section>
      </main>
    </>
  );
}

export default Turf;

const turfproducts = [
  {
    id: 1,
    cat: "Turf Gator",
    name: "2030A ProGator™ Utility Vehicle",
    image: "../images/2030a.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/turf-gators/2030a-progator-utility-vehicle/",
  },
  {
    id: 2,
    cat: "Turf Gator",
    name: "Gator™ TX TurfUtility Vehicle",
    image: "../images/gatortx.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/turf-gators/gator-tx-turf-utility-vehicle/",
  },
  {
    id: 3,
    cat: "Turf Gator",
    name: "TE 4x2 Electric Utility Vehicle",
    image: "../images/te4x2.jpg",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/turf-gators/te-4x2-electric-utility-vehicle/",
  },
  {
    id: 4,
    cat: "Turf Gator",
    name: "HD200SelectSpray™ Sprayer",
    image: "../images/hd200.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/turf-gators/hd200-selectspray/",
  },
  {
    id: 5,
    cat: "Turf Gator",
    name: "HD300SelectSpray™ Sprayer",
    image: "../images/hd300.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/turf-gators/hd300-selectspray/",
  },
  // Traditional Utiility Vehicle
  {
    id: 6,
    cat: "TUV",
    name: "HPX615E Work Series Utility Vehicle",
    image: "../images/hpx615e.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/traditional-utility-vehicles/hpx615e-utility-vehicle/",
  },
  {
    id: 7,
    cat: "TUV",
    name: "HPX815EWork Series Utility Vehicle",
    image: "../images/hpx815e.webp",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/traditional-utility-vehicles/hpx815e-utility-vehicle/",
  },
  {
    id: 8,
    cat: "TUV",
    name: "TH 6x4 Diesel",
    image: "../images/th6x4d.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/traditional-utility-vehicles/th-6x4-diesel/",
  },
  {
    id: 9,
    cat: "TUV",
    name: "TS 4x2",
    image: "../images/ts4x2.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/traditional-utility-vehicles/ts4x2/",
  },
  {
    id: 10,
    cat: "TUV",
    name: "TX 4x2",
    image: "../images/tx4x2.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/traditional-utility-vehicles/tx-4x2/",
  },
  // Mid-Size Crossover Utility Vehicles Lineup
  {
    id: 11,
    cat: "Mid-Size Crossover Utility Vehicle",
    name: "XUV 560E Crossover Utility Vehicle",
    image: "../images/xuv560e.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/crossover-utility-vehicles/xuv560e-utility-vehicle/",
  },
  {
    id: 12,
    cat: "Mid-Size Crossover Utility Vehicle",
    name: "XUV 560E S4 Crossover Utility Vehicle",
    image: "../images/xuv560es4.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/crossover-utility-vehicles/xuv560e-s4-utility-vehicle/",
  },
  {
    id: 13,
    cat: "Mid-Size Crossover Utility Vehicle",
    name: "XUV 590E Crossover Utility Vehicle",
    image: "../images/xuv590e.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/crossover-utility-vehicles/xuv590e-utility-vehicle/",
  },
  {
    id: 14,
    cat: "Mid-Size Crossover Utility Vehicle",
    name: "XUV 590M Crossover Utility Vehicle",
    image: "../images/xuv590m.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/crossover-utility-vehicles/xuv590m-utility-vehicle/",
  },
  {
    id: 15,
    cat: "Mid-Size Crossover Utility Vehicle",
    name: "XUV 590M S4 Crossover Utility Vehicle",
    image: "../images/xuv590ms4.avif",
    link: "https://www.deere.africa/en/gatorstm-utility-vehicles/crossover-utility-vehicles/xuv590m-s4-utility-vehicle/",
  },
  // Sportier Crossovers
]
