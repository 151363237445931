import React from "react";
import ProductGrid from "./productGrid";
import ProductList from "../components/ProductList";
import ProductShowcase from "../components/ProductShowcase";
import MarketingCard from "../components/MarketingCard";

function Forestry() {
  return (
    <main class="container my-5">
      <section class="mb-5">
        <div class="container-fluid align-middle head-img mb-5 ">
          <img src="../images/forestry1.avif" class="img-fluid" alt="Image" />
          <p class="centered">John Deere Forestry Machinary</p>
        </div>

        <MarketingCard image={"../images/intelligentboomcontrol.avif"} text={"Intelligent Boom Control With the optional IBC, operators no longer need to control each independent boom function separately. One joystick moves the boom tip horizontally, while a second guides it vertically, and IBC automatically guides the boom and cylinders accordingly."} />

        {/* Products start */}
        <h1>Tracker Feller Bunchers</h1>
        <div class="container-fluid text-center">
          <ProductGrid products={forestryproducts} />
        </div>

        <h1>Product Showcase</h1>
        <ProductShowcase products={forestryproducts} />

        <br />

        {/* Display Products as names and links */}
        <ProductList data={forestryproducts} />

        <div class="container-fluid">
          <img
            src="../images/forestry2.avif"
            class="img-fluid"
            alt="Image"
          />
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
            quod, itaque repellat dolorum ab dolore neque aut vitae optio culpa
            labore pariatur quos, iste fuga ad. Totam facilis quidem voluptatem.
          </p>
        </div>

        <br />

        {/* <!-- Product links --> */}
      
      </section>
    </main>
  );
}

export default Forestry;

const forestryproducts = [
  {
    id: 1,
    cat: "Tracked Feller Bunchers",
    name: "853M",
    image: "/images/853m.avif",
    link: "https://www.deere.africa/en/tracked-feller-bunchers/853m/",
  },
  {
    id: 2,
    cat: "Tracked Feller Bunchers",
    name: "859M",
    image: "/images/859m.webp",
    link: "https://www.deere.africa/en/tracked-feller-bunchers/859m/",
  },
  {
    id: 3,
    cat: "Tracked Feller Bunchers",
    name: "953M",
    image: "/images/953m.jpg",
    link: "https://www.deere.africa/en/tracked-feller-bunchers/953m/",
  },
  {
    id: 4,
    cat: "Tracked Feller Bunchers",
    name: "959M",
    image: "/images/959m.avif",
    link: "https://www.deere.africa/en/tracked-feller-bunchers/959m/",
  },
  {
    id: 5,
    cat: "KnuckleBoom Loader",
    name: "337E",
    image: "/images/337e.webp",
    link: "https://www.deere.africa/en/knuckleboom-loaders/337e/",
  },
  {
    id: 6,
    cat: "Tracked Harvester",
    name: "803MH",
    image: "/images/803mh.jpg",
    link: "https://www.deere.africa/en/tracked-harvesters/803mh/",
  },
  {
    id: 7,
    cat: "Tracked Harvester",
    name: "853MH",
    image: "/images/853mh.jpg",
    link: "https://www.deere.africa/en/tracked-harvesters/853mh/",
  },
  {
    id: 8,
    cat: "Tracked Harvester",
    name: "859MH",
    image: "/images/859mh.avif",
    link: "https://www.deere.africa/en/tracked-harvesters/859mh/",
  },
  {
    id: 8,
    cat: "Tracked Harvester",
    name: "953MH",
    image: "/images/953mh.jpg",
    link: "https://www.deere.africa/en/tracked-harvesters/953mh/",
  },
  {
    id: 10,
    cat: "Tracked Harvester",
    name: "959MH",
    image: "/images/959mh.avif",
    link: "https://www.deere.africa/en/tracked-harvesters/959mh/",
  },
  {
    id: 11,
    cat: "Wheeled Harvester",
    name: "1270G",
    image: "/images/1270g.avif",
    link: "https://www.deere.africa/en/wheeled-harvesters/1270g/",
  },
  {
    id: 12,
    cat: "Wheeled Harvester",
    name: "1570G",
    image: "/images/1470g.avif",
    link: "https://www.deere.africa/en/wheeled-harvesters/1470g/",
  },
  {
    id: 13,
    cat: "Wheeled Harvester",
    name: "1270H",
    image: "/images/1270h.avif",
    link: "https://www.deere.africa/en/wheeled-harvesters/1270h/",
  },
  {
    id: 14,
    cat: "Wheeled Harvester",
    name: "1470H",
    image: "/images/1470h.avif",
    link: "https://www.deere.africa/en/wheeled-harvesters/1470h/",
  },
]