// src/pages/home.js
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../templates_/Layout";
import Slider from "../components/Slider";

const prodimages = [
  {
    Title: "Tractors",
    url: "./images/tractor2.avif",
    link: "products/agTractors",
  },
  {
    Title: "AgImplements",
    url: "./images/425od.avif",
    link: "products/agImplements",
  },
  {
    Title: "Cane Harvesters",
    url: "./images/s7600.avif",
    link: "products/agHarvesters",
  },
  {
    Title: "Lawn & Garden",
    url: "./images/mower.avif",
    link: "products/lawngarden",
  },
  { Title: "Turf", url: "./images/gator.avif", link: "products/forestry" },
  {
    Title: "Forestry",
    url: "./images/forestry/1470G_1366x768_large.avif",
    link: "products/forestry",
  },
  {
    Title: "Construction",
    url: "./images/850j_ii_dozer.webp",
    link: "products/construction",
  },
];

function HomePage() {
  return (
    <>
      <main className="container my-5">
        <section className="mb-5">
          <div className="text-center">
            <img
              src="../images/r1.jpg"
              className="img-fluid"
              alt="Agricultural Machinery"
            />
            <h1 className="my-3 ">SwaziTrac Agriculture Products</h1>
            <p>
              SwaziTrac offers a wide range of agricultural machinery and
              equipment, designed to meet the needs of the local agricultural
              sector.
            </p>
          </div>

          {/* Turn into carousel */}
          <Slider prodimg={prodimages} />

          {/* <div className="supplier-ribbon">
            <div className="col">
              <div className="card h-100 text-center">
                <Link to="products/agTractors">
                  <img
                    src="../images/tractor2.avif"
                    className="card-img-top img-fluid"
                    alt="Agriculture"
                    style={{ maxHeight: "150px", objectFit: "cover" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Tractors</h5>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col">
              <div className="card h-100 text-center">
                <Link to="products/agHarvesters">
                  <img
                    src="../images/sugarcane1.jpg"
                    className="card-img-top img-fluid"
                    alt="Agriculture"
                    style={{ maxHeight: "150px", objectFit: "cover" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Harvesters</h5>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col">
              <div className="card h-100 text-center">
                <Link to="products/agimplements">
                  <img
                    src="../images/425od.avif"
                    className="card-img-top img-fluid"
                    alt="Agriculture"
                    style={{ maxHeight: "150px", objectFit: "cover" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Agriculural Implements</h5>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col">
              <div className="card h-100 text-center">
                <Link to="products/lawn-garden">
                  <img
                    src="images/e140_lawn_tractor.webp"
                    className="card-img-top img-fluid"
                    alt="Lawn & Garden"
                    style={{ maxHeight: "150px", objectFit: "cover" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Lawn & Garden</h5>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 text-center">
                <Link to="products/turf">
                  <img
                    src="../images/xuv_590m_s4.avif"
                    className="card-img-top img-fluid"
                    alt="Golf & Sports Turf"
                    style={{ maxHeight: "150px", objectFit: "cover" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Turf Golf & Sports</h5>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 text-center">
                <Link to="products/construction">
                  <img
                    src="../images/850j_ii_dozer.webp"
                    className="card-img-top img-fluid"
                    alt="Construction"
                    style={{ maxHeight: "150px", objectFit: "cover" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Construction</h5>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 text-center">
                <Link to="products/forestry">
                  <img
                    src="../images/forestry/1470G_1366x768_large.avif"
                    className="card-img-top img-fluid"
                    alt="Forestry"
                    style={{ maxHeight: "150px", objectFit: "cover" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Forestry</h5>
                  </div>
                </Link>
              </div>
            </div>
          </div> */}
        </section>

        <div className="container-fluid align-middle head-img">
          <img src="../images/r3.jpg" className="img-fluid" alt="Image" />
          <p className="centered">
            SwaziTrac is the official John Deere dealership in Eswatini,
            responsible for distributing a wide range of John Deere agricultural
            and construction equipment throughout the country. Based in
            Matsapha, SwaziTrac plays a crucial role in supporting local farmers
            and construction companies by providing high-quality machinery,
            parts, and superior after-sales customer service.
          </p>
        </div>

        <section id="news" className="container-fluid mb-3">
          <div className="full-width-green top-rounded mb-3">News</div>
          <div className="row row-cols-2 row-cols-md-3">
            <div className="col-lg-4 col-md-6">
              <div className="card shadow-sm">
                <div className="card-img-overlay justify-content-center align-items-center">
                  <h3 className=" overlay-title">Title</h3>
                </div>
                image
                <img
                  src="./images/460p_adt_r4j018797_large.avif"
                  className="card-img-top img-fluid"
                  alt="News Image"
                />
                <div className="card-body">
                  <p className="card-text">text body</p>
                  <p className="card-footer text-muted">
                    <a href="#" className="btn btn-primary btn-news">
                      Read More
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="container-fluid mb-3">
          <div className="full-width-green top-rounded">About Swazi Trac</div>
          <div className="img-fluid mb-3">
            <img
              src="images/full-marketing-1.jpg"
              className="img-fluid"
              alt="Image"
            />
          </div>
          <p>
            Established in 1975, Swazi-Trac has grown and expanded over the
            years to become Swaziland's biggest supplier for some of the world's finest
            agricultural and construction machinery and equipment. Whether your
            business is farming, earthmoving or making home gardens beautiful we
            would be delighted to assist you with our products and services.
          </p>

          <p>
            Our loyal client base, both commercial and private, continues to
            grow due to the quality of our products and excellent after sales
            service and support.
          </p>

          <p>
            Our company policy is to concentrate on providing the best quality
            machinery available to the customers in our geographical areas of
            operation, and to back it up with the best after-sales and
            technical/parts support in the region. To this end, we stock a
            higher than average selection of genuine parts, backed up by daily
            courier deliveries from South Africa and US/European parts
            distribution centres.
          </p>

          <p>
            Our technicians are factory trained and equipped with special tools
            and the latest computerized diagnostic tools. Several mobile service
            vehicles provide on-site service throughout eSwatini.
          </p>

          <p>
            This strategy has resulted in a very high level of Customer
            Satisfaction (93% as last measured by John Deere), dominant market
            shares in most product categories (for example, over 80% on tractor
            unit sales in eSwatini as measured by Agfacts), and very high levels
            of customer goodwill overall.
          </p>
        </section>

        <section id="suppliers" className="container-fluid mb-3">
          <div className="full-width-green top-rounded text-center py-3">
            Our Suppliers
          </div>
          <div className="supplier-ribbon">
            <div className="supplier">
              <Link to="/products/agTractors">
                <img src="images/johndeere-logo.png" alt="John Deere" />
              </Link>
            </div>
            <div className="supplier">
              <Link to="/products/falcon">
                <img src="images/falcon-logo.png" alt="Falcon" />
              </Link>
            </div>
            <div className="supplier">
              <Link to="/products/rovic">
                <img src="images/rovic-logo-jpeg_idpt.webp" alt="Rovic" />
              </Link>
            </div>
            <div className="supplier">
              <Link to="/products/mospare">
                <img src="images//mospare-logo.png" alt="MoSpare" />
              </Link>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
function Home() {
  return (
    <Layout
      children={
        <>
          <HomePage />
        </>
      }
    />
  );
}

export default Home;
