import React from 'react';

function Footer() {
  return (
      <footer className="bg-dark text-white text-center py-4">
          <div className="container">
              <p className="mb-2">&copy; 2024 SwaziTrac. All rights reserved.</p>
              <div>
                  <a href="/privacy-policy" className="text-decoration-none text-white me-3">
                      Privacy Policy
                  </a>
                  |
                  <a href="/terms-of-service" className="text-decoration-none text-white mx-3">
                      Terms of Service
                  </a>
                  |
                  <a href="/contact-us" className="text-decoration-none text-white ms-3">
                      Contact Us
                  </a>
              </div>
              <div className="mt-3">
                  <a href="https://facebook.com/swaziTrac" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
                      <i className="fab fa-facebook"></i>
                  </a>
                  <a href="https://twitter.com/swaziTrac" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
                      <i className="fab fa-twitter"></i>
                  </a>
                  <a href="https://linkedin.com/company/swaziTrac" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
                      <i className="fab fa-linkedin"></i>
                  </a>
              </div>
          </div>
      </footer>
  );
}

export default Footer;