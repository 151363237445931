import React from "react";
import ProductGrid from "./productGrid";
import ProductList from "../components/ProductList";
import ProductShowcase from "../components/ProductShowcase";


const lawnProducts = [
  {
    id: 1,
    cat: "Lawn Tractor",
    name: "E100 Lawn Tractor",
    image: "/images/e100.webp",
    link: "https://www.deere.africa/en/front-end-loaders-tractors/",
  },
  {
    id: 2,
    cat: "Lawn Tractor",
    name: "E110 Lawn Tractor",
    image: "/images/e110.webp",
    link: "https://www.deere.africa/en/mowers/lawn-tractors/120-series/e120-lawn-tractor/",
  },
  {
    id: 3,
    cat: "Lawn Tractor",
    name: "E130 Lawn Tractor",
    image: "/images/e130.avif",
    link: "https://www.deere.africa/en/mowers/lawn-tractors/120-series/e130-lawn-tractor/",
  },
  {
    id: 4,
    cat: "Lawn Tractor",
    name: "E140 Lawn Tractor",
    image: "/images/e140.webp",
    link: "https://www.deere.africa/en/mowers/lawn-tractors/100-series/e140-lawn-tractor/",
  },
  {
    id: 5,
    cat: "Lawn Tractor",
    name: "E180 Lawn Tractor",
    image: "/images/e180.avif",
    link: "https://www.deere.africa/en/mowers/lawn-tractors/100-series/e180-lawn-tractor/",
  },
  {
    id: 6,
    cat: "Residential ZTrak",
    name: "Z540R Residential ZTrak™ Mower",
    image: "/images/z540r.avif",
    link: "https://www.deere.africa/en/mowers/residential-ztrak-zero-turn-mowers/z540r-48-54-ztrak-mower/z540r-ztrak-mower/",
  },
  {
    id: 7,
    cat: "Residential ZTrak",
    name: "Z335E ZTrak™ Mower",
    image: "/images/z335e.avif",
    link: "https://www.deere.africa/en/mowers/residential-ztrak-zero-turn-mowers/z335e-ztrak-mower/",
  },
  {
    id: 8,
    cat: "Residential ZTrak",
    name: "Z345M ZTrak™ Mower",
    image: "/images/z345m.avif",
    link: "https://www.deere.africa/en/mowers/residential-ztrak-zero-turn-mowers/z345m-residential-ztrak-mower/",
  },
  {
    id: 9,
    cat: "Residential ZTrak",
    name: "Z225E ZTrak™ Mower",
    image: "/images/z525e.avif",
    link: "https://www.deere.africa/en/mowers/residential-ztrak-zero-turn-mowers/z525e-residential-ztrak-mower/",
  },
];
function LawnGarden() {
  return (
    <main class="container my-5">
      <section class="mb-5">
        <div class="container-fluid align-middle head-img mb-5 ">
          <img src="../images/mower.avif" class="img-fluid" alt="Image" />
          <p class="centered">Purchase a lawnmower for your home or bussiness, choose from a large range of Mowers</p>
        </div>

        <div class="container-fluid text-center">
          <h3>What We Sell</h3>
          <br />
          <div class="row">
            <div class="col-sm-3">
              <img
                src="../images/lawnmower2.jpg"
                class="img-fluid"
                alt="Image"
              />
              <p>Mower</p>
            </div>
            <div class="col-sm-3">
              <img
                src="../images/lawnmower3.avif"
                class="img-fluid"
                alt="Image"
              />
              <p>Mower1</p>
            </div>
            <div class="col-sm-3">
              <img
                src="https://placehold.it/150x80?text=IMAGE"
                class="img-fluid"
                alt="Image"
              />
              <p>Mower2</p>
            </div>
            <div class="col-sm-3">
              <div class="well">
                <p>
                  Lawnmowers mow grass very well. Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Dignissimos non debitis quo
                  enim, possimus est consequuntur. Quidem, rerum inventore culpa
                  velit nam unde sunt? Expedita aliquid necessitatibus atque
                  excepturi neque.
                </p>
              </div>
              <div class="well">
                <p>Some text..</p>
              </div>
            </div>
          </div>
        </div>

        {/* Products start */}
        <h1>Agricultural Implements</h1>
        <div class="container-fluid text-center">
          <ProductGrid products={lawnProducts} />
        </div>

        <h1>Product Showcase</h1>
        <ProductShowcase products={lawnProducts} />

        <br />

        {/* Display Products as names and links */}
        <ProductList data={lawnProducts} />

        <div class="container-fluid">
          <img
            src="../images/r4a063080-ag-1366x347.jpg"
            class="img-fluid"
            alt="Image"
          />
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
            quod, itaque repellat dolorum ab dolore neque aut vitae optio culpa
            labore pariatur quos, iste fuga ad. Totam facilis quidem voluptatem.
          </p>
        </div>

        <br />

        {/* <!-- Product links --> */}
      
      </section>
    </main>
  );
}

export default LawnGarden;
