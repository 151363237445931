import React from "react";

const ProductShowcase = ({ products, productIds }) => {
  // Function to select 3 products based on IDs or randomly
  const selectProducts = () => {
    if (productIds && productIds.length === 3) {
      return products.filter((product) => productIds.includes(product.id));
    }
    // Pick 3 random products if no IDs are provided
    const shuffled = [...products].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const selectedProducts = selectProducts();

  if (selectedProducts.length !== 3) {
    console.error("Unable to find 3 valid products.");
    return null;
  }

  return (
    <div className="container">
      <div className="row">
        {/* Top two products */}
        {selectedProducts.slice(0, 2).map((product) => (
          <div className="col-md-6" key={product.id}>
            <div className="card mb-3">
              <img
                src={product.image}
                className="card-img-top"
                alt={product.name}
              />
              <div className="card-body">
                <h5 className="card-title">{product.name}</h5>
                <p className="card-text">{product.cat}</p>
                <a
                  href={product.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  View Product
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        {/* Bottom product */}
        <div className="col-md-12">
          <div className="card">
            <img
              src={selectedProducts[2].image}
              className="card-img-top"
              alt={selectedProducts[2].name}
            />
            <div className="card-body">
              <h5 className="card-title">{selectedProducts[2].name}</h5>
              <p className="card-text">{selectedProducts[2].cat}</p>
              <a
                href={selectedProducts[2].link}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
              >
                View Product
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductShowcase;
