import React from "react";
import { useKeenSlider } from "keen-slider/react";
import { Link } from "react-router-dom";

import "keen-slider/keen-slider.min.css";
import "../index.css";

export default function Slider({ prodimg }) {
  const [sliderRef] = useKeenSlider({
    slides: {
      perView: 4, // Default for larger screens
      spacing: 15,
    },
    breakpoints: {
      "(max-width: 768px)": {
        slides: {
          perView: 1, // 1 item per row on mobile
          spacing: 10,
        },
      },
      "(max-width: 1024px)": {
        slides: {
          perView: 2, // 2 items per row on tablets
          spacing: 10,
        },
      },
      "(min-width: 1025px)": {
        slides: {
          perView: 4, // 4 items per row on desktops
          spacing: 15,
        },
      },
    },
  });

  const result = prodimg.map((prod, index) => {
    return (
      <Link to={prod.link} key={index}>
        <div className="keen-slider__slide number-slide1 card text-center">
          <h5 className="card-title">{prod.Title}</h5>
          <img
            src={`${prod.url}`}
            className="img-fluid"
            alt="Agriculture"
            style={{ width: "fit-content" }}
          />
        </div>
      </Link>
    );
  });

  return (
    <div ref={sliderRef} className="keen-slider">
      {result}
    </div>
  );
}
