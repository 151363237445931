import React from "react";
import { Link } from "react-router-dom";

function PrecisionAg() {
    return (
        <div className="precision-agriculture">
            <header className="bg-primary text-white text-center py-5">
                <h1>Precision Agriculture</h1>
                <p>Reduce costs, increase yields, and streamline operations with advanced technology.</p>
            </header>

            <main className="container my-5">
                <section className="mb-5">
                    <h2 className="text-center">Value in Every Pass, Every Season</h2>
                    <p className="text-center">
                        From machine performance to field management to data analysis, John Deere leads the industry in providing farmers
                        the tools to monitor, manage, and maximize their operations.
                    </p>
                </section>

                <section className="features row text-center">
                    <div className="col-md-4">
                        <i className="fas fa-tractor fa-3x text-success"></i>
                        <h3>More Efficiencies</h3>
                        <p>Find the precision technology to work faster and smarter with automation and repeatability.</p>
                    </div>
                    <div className="col-md-4">
                        <i className="fas fa-dollar-sign fa-3x text-warning"></i>
                        <h3>Lower Costs</h3>
                        <p>Precise seed placement and better application accuracy mean less input and lower costs.</p>
                    </div>
                    <div className="col-md-4">
                        <i className="fas fa-bullseye fa-3x text-danger"></i>
                        <h3>Higher Yields</h3>
                        <p>Precision ag fuels better performance across fields, machines, and processes.</p>
                    </div>
                </section>

                <section className="mt-5">
                    <h2>Precision Ag Essentials</h2>
                    <p>
                        Connect your machines, people, and technology to extract valuable data from every operation. All you need is a
                        display, a receiver, JDLink™ connection, and a free John Deere Operations Center™ account.
                    </p>
                </section>

                <section className="tools row mt-4">
                    <div className="col-md-6 mb-3">
                        <img
                            src="/path-to-image.jpg"
                            alt="Manage your farm data"
                            className="img-fluid rounded"
                        />
                        <h3>Manage Your Farm Data</h3>
                        <p>
                            John Deere Operations Center™ is a cloud-based management system to monitor, organize, and analyze your data
                            anytime, anywhere.
                        </p>
                    </div>
                    <div className="col-md-6 mb-3">
                        <img
                            src="/path-to-image-2.jpg"
                            alt="Unlock more precision"
                            className="img-fluid rounded"
                        />
                        <h3>Unlock More Precision</h3>
                        <p>
                            Boost efficiency, productivity, and profitability with precision upgrades for your existing machines.
                        </p>
                    </div>
                </section>

                <section className="cta text-center my-5">
                    <h2>Fuel Your Farm with Data</h2>
                    <p>Get started with John Deere Precision Agriculture tools today.</p>
                    <a href="https://www.deere.com/en/technology-products/precision-ag-technology/operations-center/" className="btn btn-success">
                        Get Started
                    </a>
                </section>
            </main>
        </div>
    );
}

export default PrecisionAg;
