import { experimentalSetDeliveryMetricsExportedToBigQueryEnabled } from 'firebase/messaging/sw';
import React from 'react';

function Mospare() {

  return (
  <main class="container my-5">
  <section class="mb-5">
    <div class="head-img container-fluid">
      <img src="images/forestry/h424-1170g-uphill-1366x768.avif" class="img-fluid" alt="Image"/>
      <p>MoSpare. Built for the most
        demanding environments, all designed to maximize productivity and efficiency.
      </p>
    </div>

    <div class="container-fluid text-center">
      <h3>Our MoSpare</h3><br/>
      <div class="row">
        <div class="col-sm-3">
          <img src="images/forestry/1470g_ch9boom_large.avif" class="img-fluid" alt="Harvester Image"/>
          <p>Mospare 1</p>
        </div>
        <div class="col-sm-3">
          <img src="images/forestry/forwarder_1.jpg" class="img-fluid" alt="Forwarder Image"/>
          <p>MosSpare 2</p>
        </div>
        <div class="col-sm-3">
          <img src="images/forestry/fellerbuncher_1.avif" class="img-fluid" alt="Feller Buncher Image"/>
          <p>MosSpare 3</p>
        </div>
        <div class="col-sm-3">
          <div class="well">
            <p>Our range of forestry equipment is designed for efficiency and durability. Whether you're looking for a
              harvester or a feller buncher, we have the machinery you need to get the job done.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <img src="images/forestry/1470g_ch9boom_large.avif" class="img-fluid" alt="Forestry Equipment Banner"/>
      <p>MooSpare Equipment is designed to for power, and designed to last, choose the top range of equipment from us today!</p>
    </div>

    <br />

    {/* <!-- Product links --> */}
    <section class="container-fluid">
      <div class="row">
        <div class="col-sm">
          <h4 class="border border-2 rounded-1 p-1">Tracked Equipment</h4>
          <ul>
            <li><a href="https://www.deere.africa/en/tracked-feller-bunchers/">Tracked Feller Bunchers</a></li>
            <li><a href="https://www.deere.africa/en/tracked-harvesters/">Tracked Harvesters</a></li>
            <li><a href="https://www.deere.africa/en/knuckleboom-loaders/337e/">Knuckleboom Loader</a></li>
          </ul>
        </div>
        <div class="col-sm">
          <h4 class="border border-2 rounded-1 p-1">Wheeled Equipment</h4>
          <ul>
            <li><a href="https://www.deere.africa/en/wheeled-harvesters/">Wheeled Harvesters</a></li>
            <li><a href="https://www.deere.africa/en/forestry/harvesting-heads/">Harvester Heads</a></li>
            <li><a href="https://www.deere.africa/en/forestry/felling-heads/">Felling Heads</a></li>
            <li><a href="https://waratah.com/">Waratah Heads</a></li>
          </ul>
        </div>
        <div class="col-sm">
          <h4 class="border border-2 rounded-1 p-1">Transportation & Loading</h4>
          <ul>
            <li><a href="https://www.deere.africa/en/skidders/">Skidders</a></li>
            <li><a href="https://www.deere.africa/en/forwarders/">Forwarders</a></li>
            <li><a href="https://www.deere.africa/en/technology-products/forestry-and-logging-technology/">Forestry and
                Logging Technology</a></li>
          </ul>
        </div>
        <div class="col-sm">
          <h4 class="border border-2 rounded-1 p-1">Technology & Insights</h4>
          <ul>
            <li><a href="https://www.deere.africa/en/forestry/timbermatic-manager/">TimberMatic Maps</a></li>
            <li><a href="https://www.deere.africa/en/forestry/timbermatic-manager/timbermanager/">TimberManager</a></li>
            <li><a href="https://www.deere.africa/en/forestry/ibc/">Intelligent Boom Control (IBC)</a></li>
            <li><a href="https://www.deere.africa/en/forestry/forestry-articles/">Articles and Stories</a></li>
          </ul>
        </div>
      </div>
    </section>
    </section>
    </main>
    );
    }
export default Mospare;