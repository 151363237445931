import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../index.css"; // Assuming you will include custom CSS here

function Header() {
  const location = useLocation();
  const isActive = (path) =>
    location.pathname === path ? "nav-link active" : "nav-link";

  return (
    <>
      <nav className="navbar nav navbar-expand-lg shadow p-1">
        <div className="container-fluid sticky">
          <div className="cutout">
            <a className="navbar-brand" href="/">
              <img
                src="../images/2024_website_logo_large_transparent.png"
                width="300"
                height="100"
                className="d-inline-block align-top"
                alt="Logo"
              />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className={isActive("/")} to="/">
                  Home
                </Link>
              </li>

              {/* Products dropdown */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/#"
                  id="productsDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Products
                </Link>
                <ul className="dropdown-menu" aria-labelledby="productsDropdown">
                  {/* Agriculture Dropdown */}
                  <li className="dropdown-submenu">
                    <a
                      className="dropdown-item dropdown-toggle"
                      href="#"
                      id="agricultureDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Agriculture
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="agricultureDropdown">
                      <li>
                        <Link className="dropdown-item" to="/products/agTractors">
                          John Deere Tractors
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/products/agImplements">
                          John Deere Ag Implements
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/products/agHarvesters">
                          John Deere Ag Harvestors
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/products/falcon">
                          Falcon
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/products/rovic">
                          Rovic
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/products/mospare">
                          Mospare
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* Other Product Links */}
                  <li>
                    <Link className="dropdown-item" to="/products/lawn-garden">
                      Lawn & Garden
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/products/turf">
                      Turf
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/products/construction">
                      Construction
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/products/forestry">
                      Forestry
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/products/precisionag">
                      Precision Agriculture
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Parts & Service dropdown */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="partsServiceDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Parts & Service
                </Link>
                <ul className="dropdown-menu" aria-labelledby="partsServiceDropdown">
                  <li>
                    <Link className={isActive("/service/sales-support")} to="/service/sales-support">
                      Sales Support
                    </Link>
                  </li>
                  <li>
                    <a className="dropdown-item" href="https://partscatalog.deere.com/jdrc/">
                      Parts Lookup
                    </a>
                  </li>
                </ul>
              </li>

              {/* Additional Links */}
              <li className="nav-item">
                <Link className={isActive("/news")} to="/news">
                  News
                </Link>
              </li>
              <li className="nav-item">
                <Link className={isActive("/about")} to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link className={isActive("/contact")} to="/contact">
                  Contact
                </Link>
              </li>
            </ul>

            {/* Icons */}
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="https://maps.app.goo.gl/GxcHXv2VK3YFkfyK7" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-map-marker-alt"></i>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://wa.me/" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-whatsapp"></i>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="mailto:sales@swazitrac.co.sz">
                  <i className="fas fa-envelope"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
