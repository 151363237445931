// src/App.js
import React from "react";

import { useRoutes, Route, Routes} from "react-router-dom";
// import routes from './routes/routes';

import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Contact from "./components/Contact";

import About from "./pages/AboutPage";
import Support from "./pages/support";
import Agriculture from "./pages/agriculture";
import Construction from "./pages/construction";
import Forestry from "./pages/forestry";
import Turf from "./pages/turf";
import AgTractors from "./pages/agTractors";  // double nested
import Falcon from "./pages/falcon";
import Rovic from "./pages/rovic";
import Mospare from "./pages/mospare";
import routes from "./routing/routing";
import Home from "./pages/HomePage";
import AgImplements from "./pages/agImplements";
import AgHarvesters from "./pages/agHarvesters";
import PrecisionAg from "./pages/PrecisionAg"; 
// import Contact from './pages/contact';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/agriculture" element={<Agriculture />} />
        <Route path="/agTractors" element={<AgTractors />} />
        <Route path="/agImplements" element={<AgImplements />} />
        <Route path="/agHarvesters" element={<AgHarvesters />} />
        <Route path="/falcon" element={<Falcon />} />
        <Route path="/rovic" element={<Rovic />} />
        <Route path="/mospare" element={<Mospare />} />
        <Route path="/construction" element={<Construction />} />
        <Route path="/forestry" element={<Forestry />} />
        <Route path="/turf" element={<Turf />} />
        <Route path="/about" element={<About />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/support" element={<Support />} />
        <Route path="/salessupport" element={<Support />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/PrecisionAg" element={<PrecisionAg/>} />
        {/* <Route path="/contact" element={<Contact />} /> */}
      </Routes>
      <Contact />
      <Footer />
    </>
  );
}

// export default App;

const AppRoutes = () => {
  const content = useRoutes(routes);
  return content;
};

export default AppRoutes;


