import { experimentalSetDeliveryMetricsExportedToBigQueryEnabled } from 'firebase/messaging/sw';
import react from 'react';
import ProductGrid from "./productGrid"

const harvesterProducts = [
  {
    id: 1,
    name: "X9 1100 Combine Harvester",
    image: "/images/x91100.avif",
    link: "https://www.deere.africa/en/combine-harvesters/combines/x-series-combines/",
  },
  {
    id: 2,
    name: "S7 600 Combine Harvester",
    image: "/images/s7600.avif",
    link: "https://www.deere.africa/en/combine-harvesters/combines/s7-series-combines/s7-600-combines/"
  },
  {
    id: 2,
    name: "S7 700 Combine Harvester",
    image: "/images/s7700.avif",
    link: "https://www.deere.africa/en/combine-harvesters/combines/s7-series-combines/s7-700-combines/"
  },
  {
    id: 2,
    name: "S7 800 Combine Harvester",
    image: "/images/s7800.avif",
    link: "https://www.deere.africa/en/combine-harvesters/combines/s7-series-combines/s7-800-combines/"
  },
  {
    id: 2,
    name: "S7 900 Combine Harvester",
    image: "/images/s7900.avif",
    link: "https://www.deere.africa/en/combine-harvesters/combines/s7-series-combines/s7-900-combines/"
  },
  {
    id: 3,
    name: "C1 200 Combine Harvester",
    image: "/images/c1200.avif",
    link: "https://www.deere.africa/en/combine-harvesters/combines/c-series-combines/c1200/"
  },
  {
    id:4,
    name: "S760 Combine Harvester",
    image: "/images/915vr.jpg",
    link: "https://www.deere.africa/en/crop-harvesting/combines/s-series-combines/s760-combine/"
  }
  ,
  {
    id:5,
    name: "S770",
    image: "/images/2700mr.avif",
    link: "https://www.deere.africa/en/crop-harvesting/combines/s-series-combines/s770-combine/"
  }
  ,
  {
    id:6,
    name: "S780 Combine Harvester",
    image: "/images/2730cr.webp",
    link: "https://www.deere.africa/en/crop-harvesting/combines/s-series-combines/s780-combine/"
  }
  ,
  {
    id:7,
    name: "S790 Combine Harvester",
    image: "/images/2430cp.jpg",
    link: "https://www.deere.africa/en/crop-harvesting/combines/s-series-combines/s790-combine/"
  }

];

function AgHarvesters() {
  return (
    <main class="container my-5">
    <section class="mb-5 ">
    <div class="head-img container-fluid">
          <img src="/images/harvesterheader.avif" class="img-fluid border-top-deere" alt="Image" />
          <p>John Deere: A Legacy of Power, Precision, and Performance in Agriculture.</p>
        </div>

        <div class="">
          <p>John Deere is a world-renowned leader in agricultural machinery, known for its innovative and reliable tractors that redefine efficiency in the field. With a broad range of tractor models tailored to meet the demands of modern farming, John Deere offers cutting-edge solutions for planting, tillage, harvesting, and material handling. Built for strength and durability, John Deere tractors cater to various applications, from small-scale operations to industrial-sized farms, ensuring peak performance across diverse landscapes.</p>
          <p>As a trusted partner in agriculture, John Deere backs its equipment with unparalleled customer service, an extensive dealer network, and ready access to genuine parts. Farmers can count on John Deere to provide not only top-quality tractors but also a complete ecosystem of support and expertise. Committed to sustainability, John Deere integrates smart technologies to enhance productivity and optimize resource use, allowing farmers to achieve more with less.</p>
          <br></br>
          <p>Explore how John Deere tractors can empower your farm operations and drive sustainable growth.</p>
        </div>

        <div class="container-fluid text-center">
          <ProductGrid products={harvesterProducts} />
          <h3>John Deere Tractors</h3><br/>
          <div class="row">
            <div class="col-sm-3">
              <img src="../images/johndeeretractor1.jpg" class="img-fluid" alt="John Deere Tractor Image" />
              <p>John Deere | Series 5M Tractor</p>
            </div>
            <div class="col-sm-3">
              <img src="../images/johndeeretractor2.jpg" class="img-fluid" alt="John Deere Tractor Image" />
              <p>John Deere | Series 6R Tractor</p>
            </div>
            <div class="col-sm-3">
              <div class="well">
                <p>The John Deere Series 5M Tractor is designed for versatility and productivity, perfect for general farm tasks and specialized applications. With power options ranging from 55 to 125 horsepower, it combines efficiency with user-friendly controls, making it an ideal choice for modern farmers. Whether you're working with tough soil or handling large loads, the 5M Tractor’s high-performance engine and durable build ensure long-lasting performance and ease of operation.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <img src="../images/deere_tractor_header.jpg" class="img-fluid" alt="John Deere Tractors" />
        </div>
  
      <br />
  
      {/* <!-- Product links --> */}
      <section class="container-fluid">
        <div class="row">
          <div class="col-sm">
            <h4 class="border border-2 rounded-1 p-1">Tracked Equipment</h4>
            <ul>
              <li><a href="https://www.deere.africa/en/tracked-feller-bunchers/">Tracked Feller Bunchers</a></li>
              <li><a href="https://www.deere.africa/en/tracked-harvesters/">Tracked Harvesters</a></li>
              <li><a href="https://www.deere.africa/en/knuckleboom-loaders/337e/">Knuckleboom Loader</a></li>
            </ul>
          </div>
          <div class="col-sm">
            <h4 class="border border-2 rounded-1 p-1">Wheeled Equipment</h4>
            <ul>
              <li><a href="https://www.deere.africa/en/wheeled-harvesters/">Wheeled Harvesters</a></li>
              <li><a href="https://www.deere.africa/en/forestry/harvesting-heads/">Harvester Heads</a></li>
              <li><a href="https://www.deere.africa/en/forestry/felling-heads/">Felling Heads</a></li>
              <li><a href="https://waratah.com/">Waratah Heads</a></li>
            </ul>
          </div>
          <div class="col-sm">
            <h4 class="border border-2 rounded-1 p-1">Transportation & Loading</h4>
            <ul>
              <li><a href="https://www.deere.africa/en/skidders/">Skidders</a></li>
              <li><a href="https://www.deere.africa/en/forwarders/">Forwarders</a></li>
              <li><a href="https://www.deere.africa/en/technology-products/forestry-and-logging-technology/">Forestry and
                  Logging Technology</a></li>
            </ul>
          </div>
          <div class="col-sm">
            <h4 class="border border-2 rounded-1 p-1">Technology & Insights</h4>
            <ul>
              <li><a href="https://www.deere.africa/en/forestry/timbermatic-manager/">TimberMatic Maps</a></li>
              <li><a href="https://www.deere.africa/en/forestry/timbermatic-manager/timbermanager/">TimberManager</a></li>
              <li><a href="https://www.deere.africa/en/forestry/ibc/">Intelligent Boom Control (IBC)</a></li>
              <li><a href="https://www.deere.africa/en/forestry/forestry-articles/">Articles and Stories</a></li>
            </ul>
          </div>
        </div>
      </section>
      </section>
      </main>
);
}

export default AgHarvesters; // export